@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --default-font-family: "Inter", sans-serif;
  --default-font-size: 14px;
  --default-font-weight: 400;
 
}

:root,
[data-bs-theme=light] {
    --bs-info-rgb: 26, 162, 184;
}
.badge {
  --bs-badge-font-size: 1em;  
}

body {
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  font-weight: (var(--default-font-weight));
}

.custom-table-header {
  --bs-table-bg: #f8f9fa;
  height: 60px;
}

.ggomLY {
  white-space: unset !important;
}

.custom-table-body {
  --bs-table-bg: #ffffff;
}

body {
  background-color: #f3f3f3;
}

/* AuthLayout Page */
.full-screen {
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f79b4;
}

.white-box {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  /* max-height: 70vh; */
  max-width: 60vh;
  word-wrap: break-word;
  text-align: -webkit-center !important;
}

.sigin-box {
  text-align: -webkit-center !important;
}

.auth-box {
  display: "flex";
  justify-content: "center";
}

.full-height {
  height: 100vh;
}

/* SignIn Page */
.auth-container {
  display: contents;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logo {
  max-width: 100%;
  max-height: 100%;
  margin: 5px;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.PhoneInputInput:focus-visible {
  border: none;
  outline: none;
}

.PhoneInputInput {
  border: none;
  outline: none;
}

.copy-button {
  background-color: rgb(243, 242, 227);
  color: #000;
  padding: 2px 10px;
  cursor: copy;
  border: 0px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: #bfbfc0;
}

.row-form-control {
  font-size: 12px;
  padding: 3px 0px;
  margin: 6px 0px;
}

.form-control-bottom-border {
  border: none;
  /* Remove default border */
  border-bottom: 1px solid #ced4da;
  /* Add bottom border */
  border-radius: 0;
  /* Optional: Remove border radius */
}

.form-control-bottom-border:focus,
.form-control-bottom-border:focus-visible {
  border-bottom: 2px solid #007bff;
  box-shadow: none;
  /* Change bottom border on hover */
  border-radius: 0;
  outline-style: none;
}

/* dnd table row style */
span.select__indicator-separator.css-1ovo95q-indicatorSeparator,
span.css-1ovo95q-indicatorSeparator {
  background-color: var(--bs-table-bg-type);
}

.select__control.css-1n7ccwe-control,
.form-control-bottom-border>div>div>div.select__control.css-1m9x2j-control {
  border: 0px !important;
  border-radius: 0px !important;
  border-bottom: 1px solid var(--bs-table-border-color) !important;
}

.css-1n7ccwe-control,
.form-control-bottom-border>div>div>div.select__control.css-1m9x2j-control {
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid var(--bs-table-border-color) !important;
}

.css-1n7ccwe-control:hover,
.css-1n7ccwe-control:focus,
.form-control-bottom-border>div>div>div.select__control.css-1m9x2j-control:focus,
.css-1n7ccwe-control:focus-visible,
.form-control-bottom-border>div>div>div.select__control.css-1m9x2j-control:focus-visible {
  border: 0px !important;
  border-bottom: 2px solid #007bff !important;
  box-shadow: none !important;
  /* Change bottom border on hover */
  border-radius: 0 !important;
  outline-style: none !important;
}

.font-12 {
  font-size: 12px;
}

.react-datepicker__navigation--years {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.current-stock-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 150px;
}

.stock-and-change {
  display: flex;
  align-items: center;
}

.edit-button-span {
  margin-left: 10px;
  /* Adjust the margin as needed */
}

/* Hide the default radio button */
.custom-radio input[type="radio"] {
  display: none;
}

/* Create a custom square radio button */
.custom-radio input[type="radio"]+.form-check-label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #cccccc;
  border-radius: 4px;
  margin-right: 5px;
  vertical-align: middle;
  background-color: white;
  transition: background-color 0.2s, border-color 0.2s;
  text-align: center;
  line-height: 18px;
}

/* Style the square radio button when checked */
.custom-radio input[type="radio"]:checked+.form-check-label::before {
  border-color: #007bff;
}

/* Green background and '+' sign for 'Add' action */
.custom-radio input[type="radio"][data-action="add"]:checked+.form-check-label::before {
  background-color: green;
  content: "+";
  border: none;
  color: white;
  font-weight: bold;
}

/* Red background and '-' sign for 'Reduce' action */
.custom-radio input[type="radio"][data-action="reduce"]:checked+.form-check-label::before {
  background-color: red;
  border: none;
  content: "-";
  color: white;
  font-weight: bold;
}

/* Move the second radio button slightly when the first one is clicked */
.custom-radio:first-of-type input[type="radio"]:checked~.custom-radio {
  transform: translateY(5px);
  transition: transform 0.2s;
}

/* Optional checkmark - hidden by default */
.custom-radio input[type="radio"]:checked+.form-check-label::after {
  content: "";
  display: inline-block;
  background-color: white;
  position: relative;
  top: -14px;
  left: 4px;
}

.select__control--is-disabled {
  background-color: #E9ECEF !important;
  border: 1px solid #dee2e6 !important;
}

.form-control:disabled {
  color: hsl(0, 0%, 60%);
}
/* .form-group {
  margin-bottom: 15px;
}  */
.text-capitalize{
  text-transform: capitalize;
}
.table-heder{
  position: sticky;
  top: -0.5%;
}
.no-arrow::after {
  display: none !important;
}

.rdt_TableHeadRow .rdt_TableCol:last-child {
  position: sticky;
  right: 0;
  background-color: #fff;
  /* Match the table's background */
  z-index: 3;
  /* Ensure the header is above body rows */
}
.btn-group-sm>.btn,
.btn-sm {
  --bs-btn-padding-y: 0.15rem !important;
  --bs-btn-padding-x: 0.7rem !important;
  font-weight: bold !important;
}
.navbar-brand img {
  vertical-align: middle;
}

.navbar-nav .nav-link {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}
.custom-button {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 2px solid #008055;
}

.custom-button.outline {
  background-color: transparent;
  color: #008055;
}

.custom-button.outline:hover {
  background-color: #008055;
  color: white;
}
.cursor-pointer {
  cursor: pointer !important;
}
.badge-draft {
  background-color: #6c757d !important;
}
.badge-pending {
  background-color: #6c757d !important;
}
.badge-sent {
  background-color: #0D6EFD !important;
  color: #ffffff;
}
.badge-partially-refunded{
  background-color: #5e3131 !important;
}
.badge-partially-paid {
  background-color: #197387 !important;
}

.badge-paid {
  background-color: #3A833A !important;
}

.badge-refunded {
  background-color: #dc3545 !important;
}
.badge-pending {
  background-color: #AC8B46 !important;
}
.badge-rejected {
    background-color: #DC3545 !important;
}
.badge-auto {
  background-color: #197387 !important;
}
.badge-auto-approve {
  background-color: #197387 !important;
}
.badge-approved {
  background-color: #198754 !important;
}
.whitespace-pre {
  white-space: pre-wrap;
}